@import "~normalize.css/normalize.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

body {
  margin: 0;
  overflow-y: scroll;
  font-family: system-ui, 
               -apple-system, BlinkMacSystemFont, 
               "Segoe UI", 
               "Roboto", 
               "Oxygen", 
               "Ubuntu", 
               "Cantarell", 
               "Fira Sans", 
               "Droid Sans", 
               "Helvetica Neue", 
               Arial, sans-serif;
}

@media screen {
  body.dark-mode {
    background-color: #30404d;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

code {
  font-family: ui-monospace, 
               Menlo, Monaco, 
               "Cascadia Mono", "Segoe UI Mono", 
               "Roboto Mono", 
               "Oxygen Mono", 
               "Ubuntu Monospace", 
               "Source Code Pro",
               "Fira Mono", 
               "Droid Sans Mono", 
               "Courier New", monospace;
}
