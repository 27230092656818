.App {
  text-align: center;
  padding-bottom: 250px;
}

.bp4-overlay { z-index: 30 }

main {
  position: relative; 
  z-index: 1; 
  padding: 85px 15px 0;
}

main > .bp4-button-group {
  display: block;
}

.App > header {
  position: fixed; 
  width: 100%;
  z-index: 2;
}

main h3 {
  text-align: left;
}

.App-bar {
  margin: 0 -7.5px 40px;
}

@media screen {
  .App-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media print {
  .App-bar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    text-align: left;
    margin-top: 10px;
    break-after: page;
  }
  .no-print {
    visibility: hidden;
  }
}

.App-bar .App-slot {
  width: 250px;
  margin: 7.5px;
  margin-bottom: 15px;
  padding: 0;
  cursor: pointer;
  /* overflow: hidden; */
}

@media screen {
  .App-bar .App-slot {
    display: flex;
    flex-direction: column;
    height: calc(100% - 22.5px);
  }
}

@media print {
  .App-bar .App-slot {
    display: inline-block;
    vertical-align: text-top;
    height: 100%;
    width: 218px;
  }
}

/* Optimize width for various paper sizes (tested in Chrome) */
@media print and (min-width: 190mm) {
  .App-bar .App-slot {
    width: 242px;
  }
}

@media print and (min-width: 290mm) {
  .App-bar .App-slot {
    width: 234px; 
  }
}

.App-bar .App-slot > .bp4-dialog-header {
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 7.5px 15px;
  border-radius: 3px 3px 0 0;
  background: #ebf1f5;
}

 @media screen{ .bp4-dark .App-bar .App-slot > .bp4-dialog-header {
  background: #293742;
} }

.App-bar .App-slot.bp4-intent-danger > .bp4-dialog-header {
  background: repeating-linear-gradient( -45deg, rgba(219, 55, 55, 0.20), rgba(219, 55, 55, 0.20) 10px, rgba(219, 55, 55, 0.10) 10px, rgba(219, 55, 55, 0.10) 20px );
}

 @media screen{ .bp4-dark .App-bar .App-slot.bp4-intent-danger > .bp4-dialog-header {
  background: repeating-linear-gradient( -45deg, rgba(219, 55, 55, 0.20), rgba(219, 55, 55, 0.20) 10px, rgba(219, 55, 55, 0.30) 10px, rgba(219, 55, 55, 0.30) 20px );
} }

.App-bar .App-slot > .bp4-dialog-header > * { 
  width: 100%;
}

.App-bar .App-slot > .bp4-dialog-body {
  margin-top: 0;
  margin-bottom: 0;
}

.App-bar .App-slot > .bp4-dialog-footer {
  margin: 0;
  padding: 7.5px 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -1px 0 rgb(219 220 221);
  margin-top: 1px;
  border-radius: 0;
}

 @media screen{ .bp4-dark .App-bar .App-slot > .bp4-dialog-footer {
  background: #30404d;
  box-shadow: 0 -1px 0 rgba(16, 22, 26, 0.4); 
} }

.App-school {
  text-align: left;
  padding-top: 10px; 
  padding-bottom: 10px;
  margin-right: -20px;
  margin-left: -20px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  cursor: pointer;
}
 @media screen{ .bp4-dark .App-school {
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); 
} }

@media print {
  .App-school-opened .App-school-title {
    font-weight: 600;
  }
}

.App-school-opened {
  border-left: 3px solid #106ba3;
  padding-left: calc(15px - 3px);
}
 @media screen{ .bp4-dark .App-school-opened {
  border-color: #48aff0;
} }
.bp4-card.bp4-card.bp4-intent-danger .App-school-opened {
  border-color: #db3737;
}
 @media screen{ .bp4-dark .bp4-card.bp4-card.bp4-intent-danger .App-school-opened {
  border-color: #F55656
} }

.space-between {
  display: flex;
  justify-content: space-between;
}
.align-items-center {
  display: flex;
  align-items: center;
}

.App-popover {
  width: 380px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
@media print {
  .bp4-portal {
    display: none;
  }
}

.App-popover .bp4-dialog-body  {
  margin: 15px;
}
.App-popover .bp4-tag-remove {
  display: none;
}
.App-popover .bp4-control-group {
  justify-content: space-between;
}
.App-popover .bp4-form-group {
  flex-direction: row;
}
.App-popover .bp4-label {
  margin-top: 6px;
  padding-right: 5px;
  width: 65px;
  text-align: right;
  font-size: smaller;
}
.App-popover .bp4-form-content {
  flex: 1;
  min-width: 0;
}
.App-popover .bp4-form-content .bp4-button-text{
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.App-popover .bp4-editable-text {
  font-weight: bold;
}

.App-itinerary {
  max-width: 820px;
  margin: 0 auto 20px;
  text-align: left;
}
@media print { 
  .App-itinerary {
    margin-top: 130px;
  }
}
.App-itinerary h1 {
  margin-top: 30px;
  margin-bottom: 0;
}
.App-itinerary dl {
  display: grid;
  grid-template-columns: 100px 1fr;
  position: relative;
  margin-left: 25px;
}
.App-itinerary dt, .App-itinerary dd {
  position: relative;
  margin-bottom: 5px;
}
.App-itinerary dt:after {
  content: ':'
}
.App-itinerary dd {
  text-align: left;
  margin: 0;
}
.App-itinerary dl.App-timeline:before {
  content: '';
  border-left: 3px solid #106ba3;
  width: 1px;
  position: absolute;
  left: -21px;
  top: 5px;
  bottom: 10px;
}
.App-itinerary dl.App-timeline dt:before {
  content: '';
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: white;
  border: 3px solid #106ba3;
  position: absolute;
  left: -26px;
  top: 2px;
}
 @media screen{ .bp4-dark .App-itinerary dl.App-timeline:before,
.bp4-dark .App-itinerary dl.App-timeline dt:before {
  background: #30404d;
  border-color: #48aff0;
} }

.danger-text {
  color: #db3737
}
 @media screen{ .bp4-dark .danger-text {
  color: #F55656
} }

@page {
  margin: 0.4in;
}

@media print { 
  .App {
    padding-bottom: 0;
  }

  main {
    padding-top: 0;
  }

  .App > header, 
  main > .bp4-button-group {
    display: none;
  }

  section, .bp4-card {
    page-break-inside: avoid;
  }

  .bp4-heading {
    break-after: avoid;
  }

  /* h1, h2, h3, h4, h5, h6 {
    page-break-inside: avoid;
  }

  h1:after, h2:after, h3:after, h4:after, h5:after, h6:after {
    content: "";
    display: block;
    height: 100px;
    margin-bottom: -100px;
  } */
}

.App-warnings {
  max-height: calc(100vh - 75px);
  overflow-y: auto;
  overscroll-behavior-y: contain;
}

.bp4-select-popover .bp4-menu {
  overscroll-behavior-y: contain;
}
